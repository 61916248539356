import { setLocalStorageItem } from "@/utils/localStorageUtility";
import {
  taskHeaders,
  taskLocalStorageKeys
} from "@/model/workflow/task/taskModel";

/**
 * Inbox Filter Mixin
 */
export const inboxFilterMixin = {
  components: {
    ReportDialog: () => import("@/components/shared/core/dialogs/ReportDialog"),
    InboxFilterMenu: () =>
      import("@/components/shared/core/menu/InboxFilterMenu")
  },
  data() {
    return {
      selectedTasks: [],
      reportHeaders: taskHeaders,
      visibleReportDialog: false,
      selectAllTasks: false,
      selectedFilterOption: [],
      indeterminateState: false
    };
  },
  computed: {
    /**
     * Export Command Text
     * @return {string}
     */
    commandExport() {
      return "Export";
    }
  },
  methods: {
    /**
     * Event On Tasks Selected
     * @param payload
     */
    onItemSelected(payload) {
      this.selectedTasks = payload.tasks;

      this.indeterminateState = payload.setIndeterminate;

      if (this.selectedTasks.length === this.items.length) {
        this.selectAllTasks = true;
        this.indeterminateState = false;
      }

      if (this.selectedTasks.length === 0) {
        this.selectAllTasks = false;
        this.indeterminateState = false;
      }
    },

    /**
     * Export Task Action
     */
    exportTasks() {
      this.visibleReportDialog = true;
    },

    /**
     * Event Task Filter by Status Changed
     * @param option
     */
    filterOptionChanged(option) {
      this.selectedFilterOption = option;
      setLocalStorageItem(
        taskLocalStorageKeys.taskFilterOption,
        JSON.stringify(this.selectedFilterOption)
      );
    }
  }
};
