// services
import { getStoredSelectedModuleItemId } from "@/services/inbox/inboxModuleService";

// model
import { findTaskPriority } from "@/model/workflow/task/taskModel";

// design
import { iconTask } from "@/design/icon/iconConst";

// vuex
import { createNamespacedHelpers } from "vuex";
const {
  mapState,
  mapMutations,
  mapGetters,
  mapActions
} = createNamespacedHelpers("inbox");

// store
import { SET_DRAWER_MINI } from "@/store/shared/mutationTypes/record/record-mutation-types";

// mixins
import { moduleItemsDrawerMixin } from "@/mixins/shared/base/drawer/moduleItemsDrawerMixin";
import { inboxFilterMixin } from "@/mixins/inbox/content/inboxFilterMixin";

/**
 * inboxDrawerMixin
 */
export const inboxDrawerMixin = {
  mixins: [moduleItemsDrawerMixin, inboxFilterMixin],
  data() {
    return {
      iconTask: iconTask
    };
  },
  computed: {
    ...mapState(["tasks"]),

    /**
     * Overwrite moduleItemsDrawerMixin Getters
     */
    ...mapGetters(["drawerMini", "selectedModuleItemId"]),

    /**
     * Overwrite (tasks) items
     * @return {{taskId: number, name: string, typeId: number, templateId: number, workflowInstanceId: number, workflowDefinitionId: number, recordId: number, recordName: string, status: number, statusText: string, projectId: number, projectName: string, priority: number, isOverdue: boolean, isMilestone: boolean, isDue: boolean, dueDate: string, comment: string, assignee: string}[]}
     */
    items() {
      let filteredItems = this.tasks;

      // Filter by name
      if (this.searchDrawerItemName) {
        filteredItems = filteredItems.filter(el => {
          return el.name
            .toLowerCase()
            .startsWith(this.searchDrawerItemName.toLowerCase());
        });
      }

      // Filter by selected statuses
      if (this.selectedFilterOption.length) {
        this.selectAllTasks = false; //Reset Checkbox for Report
        filteredItems = filteredItems.filter(el => {
          return this.selectedFilterOption.includes(
            findTaskPriority(el.priority)?.text
          );
        });
      }

      return filteredItems;
    },

    /**
     * Overwrite suggested Select (Task) Module Item id
     * @return {Number|number|undefined}
     */
    defaultSelectModuleItemId() {
      return this.itemCount > 0 ? this.items[0].taskId : -1;
    }
  },
  methods: {
    ...mapActions({ setTasks: "setTasks" }),

    /**
     * Overwrite moduleItemsDrawerMixin Mutations
     */
    ...mapMutations({ setDrawerMini: SET_DRAWER_MINI }),

    /**
     * Get Stored Selected Module Item id (Task id)
     * Overwrites moduleItemsDrawerMixin.getStoredSelectedModuleItemId
     * @returns {Number|number}
     */
    getStoredSelectedModuleItemId: () => getStoredSelectedModuleItemId(),

    /**
     * Overwrite Determines whether Module (Task) Item exists by its id
     * @param {Number|number} id
     * @return {Boolean|boolean}
     */
    existsModuleItem(id) {
      return this.items?.find(el => el.taskId === id) ?? undefined;
    },

    /**
     * Load, set and list Workflow User Tasks
     * @returns {void}
     */
    async listItems() {
      try {
        this.clearError();
        this.isLoading = true;
        await this.setTasks();
        await this.ensureSelectedItem();
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
